<template>
   <el-scrollbar :height="contentStyleObj">
  <div style="width:850px" v-loading="loading">
    <div class="common_dialog_box clearfix">
      <div class="common_title" style="font-weight:600">异常申报重试<span style="font-size: 13px;color: red;">*温馨提示：非申报期不重试</span></div>
      <!-- <div class="item_one clearfix">
        <span style="font-weight:600;margin-right:15px">申报重试税额限制</span>
        <span style="color:gray">各异常申报税种低于上方设置的税额,才会进行异常申报重试</span>
        <el-form :inline="true" style="margin-top:20px" :model="ruleForm" :rules="rules">
          <el-form-item prop="zzs" label="增值税≤">
            <el-input v-model="zzs" type="text" size="small" style="width:100px"></el-input>元
          </el-form-item>
          <el-form-item prop="sds" label="所得税≤">
            <el-input v-model="sds" type="text" size="small" style="width:100px"></el-input>元
          </el-form-item>
          <el-form-item prop="qts" label="其他税≤">
            <el-input v-model="qts" type="text" size="small" style="width:100px"></el-input>元
          </el-form-item>
          <el-form-item prop="yhs" label="印花税≤">
            <el-input v-model="yhs" type="text" size="small" style="width:100px"></el-input>元
          </el-form-item>
          <el-form-item prop="fjs" label="附加税≤">
            <el-input v-model="fjs" type="text" size="small" style="width:100px"></el-input>元
          </el-form-item>
        </el-form>
      </div> -->
      <div class="item_one clearfix">
        <span style="font-weight:600;margin-right:15px;font-size: 14px;">定时重试 </span>
        <!-- <span style="color:gray">请保证对应时间内,客户端和机柜开启</span> -->
        <el-form>
          <!-- <el-form-item>
            <el-checkbox v-model="checked" label="23-05点整点重试" size="large" />
          </el-form-item> -->
          <!-- <el-form-item> -->
            <!-- <el-checkbox-group v-model="checkList">
              <el-checkbox label="11">23:00</el-checkbox>
              <el-checkbox label="12">00:00</el-checkbox>
              <el-checkbox label="01">01:00</el-checkbox>
              <el-checkbox label="02">02:00</el-checkbox>
              <el-checkbox label="03">03:00</el-checkbox>
              <el-checkbox label="04">04:00</el-checkbox>
              <el-checkbox label="05">05:00</el-checkbox>
            </el-checkbox-group> -->
            <!-- <el-button @click="anniu"></el-button> -->
            <!-- <div style="background-color:#eee;width:700px">
              <el-radio style="margin-left:20px" v-model="radio" label="1" size="large">默认时间:00:00-06:00</el-radio>
              <el-radio v-model="radio" label="2" size="large">
                自定义时间
                <el-time-picker
                    is-range
                    v-model="value1"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    size="small">
                  </el-time-picker>
              </el-radio>
            </div> -->
          <!-- </el-form-item> -->
          <el-form-item>
            <el-checkbox v-model="cron_task" label="遇税局卡顿，系统每30分钟自动重试任务" size="large" />
          </el-form-item>
        </el-form>

      </div>
    </div>
    <!-- 发票预采集设置 -->
    <div class="common_dialog_box clearfix">
      <div class="common_title" style="font-weight:600">发票预采集设置<span style="font-size: 13px;color: red;">*温馨提示：默认每月1日0点自动发起采集</span></div>
      <div class="item_one clearfix">
        <span style="font-weight:600;margin-right:15px;font-size: 14px;">是否自动采集进项</span>
        <el-radio-group v-model="info.cjInvoiceInStatus">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </div>
      <div class="item_one clearfix">
        <span style="font-weight:600;margin-right:15px;font-size: 14px;">是否自动采集销项</span>
        <el-radio-group v-model="info.cjInvoiceOutStatus">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </div>
      <div class="item_one clearfix">
        <span style="font-weight:600;margin-right:15px;font-size: 14px;">采集进项内容</span>
        <el-checkbox-group
        style="display:inline-block"
          v-model="info.cjInvoiceInType"
        >
          <el-checkbox label="已认证专票"></el-checkbox>
          <el-checkbox label="未认证专票"></el-checkbox>
          <el-checkbox label="普票"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="item_one clearfix">
        <span style="font-weight:600;margin-right:15px;font-size: 14px;">小规模是否季度采集</span>
        <el-radio-group v-model="info.cjInvoiceXgm">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </div>
    </div>

    <!-- 顶部表 -->
    <div class="common_dialog_box clearfix">
      <div class="common_title" style="font-weight:600">申报设置</div>
      <div class="item_one clearfix">
        <table class="content" cellspacing="0" width="100%" style="margin-top:15px">
          <tr class="top_bg">
            <td class="center" style="width:60%">状态及项目名称</td>
            <td class="center" style="width:40%">备注</td>
          </tr>
          <tr class="tr_height">
            <td style="width:60%">凡资产负债表期末资产总额为0，默认填写<el-input type="number" v-model.number="info.zcfzMin" size="small"
                style="width:120px;margin:0 10px" />元 <br> （默认借-货币资金、贷-其他应付款）<span
                style="width:50%;color:red">（请填写0或者100以上的数字）</span></td>
            <td class="center" style="width:40%;color:red">
              应税务局要求从2020年第三季度开始，企业所得税申报表的资产总额必须和财务报表资产总额一致且不得为0。<br> 此项操作需人工操作，请谨慎修改金额！若由零申报改为税款申报，如需修改以前报表请自行去税局大厅处理。</td>
          </tr>

          <tr class="tr_height">
            <td style="width:60%">是否显示密码：
              <el-switch v-model="info.password" :active-value=1 :inactive-value=2></el-switch>
            </td>
            <td class="center" style="width:40%;color:red"></td>
          </tr>
          <tr class="tr_height">
            <td style="width:60%">凡所得税报表季初资产为0，默认填写<el-input type="number" v-model.number="info.jczc" size="small"
                style="width:120px;margin:0 10px" />元。<br> 季末资产为0，默认填写<el-input type="number" v-model.number="info.jmzc"
                size="small" style="width:120px;margin:0 10px" />元 <br> <span
                style="width:50%;color:red">（请填写0或者100以上的数字）</span></td>
            <td style="width:40%">凡所得税报表季初从业人数为0，默认填写<el-input type="number" v-model.number="info.jcrs" size="small"
                style="width:90px;margin:0 10px" />人。<br> 季末从业人数为0，默认填写<el-input type="number" v-model.number="info.jmrs"
                size="small" style="width:90px;margin:0 10px" />人</td>
          </tr>
          <tr class="tr_height">
            <td style="width:60%">是否检查利润表所得税勾稽关系：
              <el-switch v-model="info.jcLrbSds" :active-value='0' :inactive-value='1'></el-switch>
            </td>
            <td class="center" style="width:40%;color:red"></td>
          </tr>
          <tr class="tr_height">
            <td style="width:60%">检查是否检查往期：
              <el-switch v-model="info.jcLastPeriod" :active-value='0' :inactive-value='1'></el-switch>
            </td>
            <td class="center" style="width:40%;color:red"></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="common_dialog_box clearfix" v-if="orgId == 3576">
      <div class="common_title" style="font-weight:600">单据/税款确认设置</div>
      <div class="item_one clearfix">
        <span style="font-weight:600;margin-right:15px;font-size: 14px;">单据确认设置</span>
        <el-radio-group v-model="info.ackDjStatus">
          <el-radio :label="0">无需确认</el-radio>
          <el-radio :label="1">手工确认</el-radio>
        </el-radio-group>
      </div>
      <div class="item_one clearfix">
        <span style="font-weight:600;margin-right:15px;font-size: 14px;">税款确认设置</span>
        <el-radio-group v-model="info.ackTaxStatus">
          <el-radio :label="0">无需确认</el-radio>
          <el-radio :label="1">手工确认</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div style="text-align: right;">
      <el-button @click="saveMin" type="primary" size="small" style="margin-top:20px;transform: translateY(-3px);" :disabled="!$buttonStatus('shsz_bc')" :loading="btnLoading">
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
        <span> 保存</span>
      </el-button>
    </div>
  </div>
</el-scrollbar>
</template>

<script>
import { orgSettingInfo, orgSettingSave } from "@/api/org"
export default {
  name: "org-setting",
  data() {
    return {
      contentStyleObj: {}, //高度变化
      info: {
        zcfzMin: 0,
        password: 2,
        jczc: 2,
        jmzc: 2,
        jcrs: 2,
        jmrs: 2,
        sbStatus: null,
        sbTime: "",
      },
      checked: true,
      radio: '1',
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      ruleForm: {
        zzs: '',
        sds: '',
        qts: '',
        yhs: '',
        fjs: '',
      },
      rules: {
        zzs: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        sds: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        qts: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        yhs: [
          { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        ],
        fjs: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ]
      },
      checkList: [],
      cron_task:false,
      loading:false,
      btnLoading:false,
      orgId: this.$store.getters["user/user"].orgId,
    }
  },
  created() {
    // 获取表格高度
    this.contentStyleObj = this.$getHeight(166)
    // this.getList();
  },
  methods: {
    init(item){
      let info = item
      if (info.password == 0) {
        info.password = 2
      }
      this.info = info
      if (this.info.sbStatus == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
      if(this.info.cronTask == 1){
        this.cron_task = false
      }else{
        this.cron_task = true
      }
      let a = this.info.sbTime.split(',')
      this.info.cjInvoiceInType = this.info.cjInvoiceInType.split(',')
      this.checkList = a
      this.listLoading = false;
    },
    getList() {
      this.listLoading = true;
      orgSettingInfo({}).then(res => {
        let info = res.data.data
        if (info.password == 0) {
          info.password = 2
        }
        this.info = info
        if (this.info.sbStatus == 0) {
          this.checked = false
        } else {
          this.checked = true
        }
        if(this.info.cronTask == 1){
          this.cron_task = false
        }else{
          this.cron_task = true
        }
        let a = this.info.sbTime.split(',')
        this.info.cjInvoiceInType = this.info.cjInvoiceInType.split(',')
        this.checkList = a
        this.listLoading = false;
      })
    },
    saveMin() {
      this.loading = true
      this.btnLoading = true
      if (this.checked == true) {
        this.info.sbStatus = 1
      } else {
        this.info.sbStatus = 0
      }
      if(this.cron_task){
        this.info.cronTask = 0
      }else{
        this.info.cronTask = 1
      }
      let a = this.checkList.join(",")
      this.info.sbTime = a 
      this.info.zcfzMin = Number(this.info.zcfzMin);
      this.info.jczc = Number(this.info.jczc);
      this.info.jmzc = Number(this.info.jmzc);
      this.info.jcrs = Number(this.info.jcrs);
      this.info.jmrs = Number(this.info.jmrs);
      this.info.cjInvoiceInType = this.info.cjInvoiceInType.toString()
      orgSettingSave(this.info).then(res => {
        this.loading = false
        this.btnLoading = false
        if (res.data.msg == 'success') {
          this.$message({
            message: '设置成功',
            type: 'success'
          });
          this.checkList = []
          this.info.sbTime = ''
          this.$emit('success')
          // this.getList()
        }
      })
    },
    anniu() {
      //console.log(this.checkList);
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  font-size: 14px;
}

.tr_height td {
  padding: 5px !important;
}

.center {
  text-align: center;
}

.content {
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;

  .top_bg {
    td {
      font-size: 13px !important;
    }

  }

  span {
    line-height: 23px;
    font-size: 13px;
  }

  tr {
    padding: 0;
    margin: 0;
  }

  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    line-height: 23px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;

  }

  input {
    width: 100%;
    line-height: 23px;
    // padding: 0 6px;
    text-align: right;
    font-size: 13px;
  }
}
</style>
