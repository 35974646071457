<template>
  <div style="position: relative;">
    <div class="top_hint">
      <qzf-video vid="63dd60f5889e918c6bef458a6663db4a_6"></qzf-video>
      <span style="margin-left: 5px;">*温馨提示：本模块会影响账号内全部公司设置</span>
    </div>
    <el-tabs type="border-card" v-model="value" @tab-click="handleClick">
      <el-tab-pane label="凭证" name="1">
        <el-scrollbar :height="contentStyleObj">
          <div style="position: relative;width: 850px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="进项发票" name="first">
                <div class="right_title none-border">
                  <h3>凭证习惯</h3>
                  <p>
                    *温馨提示：合并凭证之后不能进行拆分，且会影响现金流量表取数，请谨慎选择合并方式！
                  </p>
                  <el-radio-group v-model="mergeSetInfo.in">
                    <div class="item_radio">
                      <el-radio :label="0">按单张发票生成凭证</el-radio>
                    </div>
                    <div class="item_radio">
                      <el-radio :label="1">按相同往来单位合并凭证</el-radio>
                    </div>
                    <div class="item_radio">
                      <el-radio :label="2">按业务类型合并凭证</el-radio>
                    </div>
                    <div class="item_radio">
                      <el-radio :label="3">按时间合并凭证</el-radio>
                    </div>
                    <div class="item_radio">
                      <el-radio :label="4">按往来单位和时间合并凭证</el-radio>
                    </div>
                    <div class="one_item">
                      <el-checkbox
                        v-model="mergeSetInfo.inEntry"
                        :true-label="1"
                        :false-label="0"
                        >结算分录合并一条</el-checkbox
                      >
                      <el-checkbox
                        v-model="mergeSetInfo.inEntry"
                        :true-label="2"
                        :false-label="0"
                        >合并分录(不合并往来单位)</el-checkbox
                      >
                    </div>
                  </el-radio-group>
                  <h3>摘要自定义</h3>
                  <p>*温馨提示：自定义凭证摘要不可合并凭证！</p>
                  <el-checkbox
                    label="默认业务类型"
                    checked
                    disabled
                    style="padding-left:30px"
                  ></el-checkbox>
                  <el-checkbox-group
                    v-model="mergeSetInfo.inSummaryArr"
                    style="padding-left:30px"
                  >
                    <el-checkbox label="开票日期"></el-checkbox>
                    <el-checkbox label="单位名称"></el-checkbox>
                    <el-checkbox label="开票项目"></el-checkbox>
                    <el-checkbox label="金额"></el-checkbox>
                    <el-checkbox label="发票号码"></el-checkbox>
                    <el-checkbox label="发票类型" value="发票类型"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-tab-pane>
              <el-tab-pane label="销项发票" name="second">
                <div class="right_title none-border">
                  <h3>凭证习惯</h3>
                  <p>
                    *温馨提示：合并凭证之后不能进行拆分，且会影响现金流量表取数，请谨慎选择合并方式！
                  </p>
                  <el-radio-group v-model="mergeSetInfo.out">
                    <div class="item_radio">
                      <el-radio :label="0">按单张发票生成凭证</el-radio>
                    </div>
                    <div class="item_radio">
                      <el-radio :label="1">按相同往来单位合并凭证</el-radio>
                    </div>
                    <div class="item_radio">
                      <el-radio :label="2">按业务类型合并凭证</el-radio>
                    </div>
                    <div class="item_radio">
                      <el-radio :label="3">按时间合并凭证</el-radio>
                    </div>
                    <div class="item_radio">
                      <el-radio :label="4">按往来单位和时间合并凭证</el-radio>
                    </div>
                    <div class="one_item">
                      <el-checkbox
                        v-model="mergeSetInfo.outEntry"
                        :true-label="1"
                        :false-label="0"
                        >结算分录合并一条</el-checkbox
                      >
                      <el-checkbox
                        v-model="mergeSetInfo.outEntry"
                        :true-label="2"
                        :false-label="0"
                        >合并分录(不合并往来单位)</el-checkbox
                      >
                    </div>
                  </el-radio-group>
                  <h3>摘要自定义</h3>
                  <p>*温馨提示：自定义凭证摘要不可合并凭证！</p>
                  <el-checkbox
                    label="默认业务类型"
                    checked
                    disabled
                    style="padding-left:30px"
                  ></el-checkbox>
                  <el-checkbox-group
                    v-model="mergeSetInfo.outSummaryArr"
                    style="padding-left:30px"
                  >
                    <el-checkbox label="开票日期"></el-checkbox>
                    <el-checkbox label="单位名称"></el-checkbox>
                    <el-checkbox label="开票项目"></el-checkbox>
                    <el-checkbox label="金额"></el-checkbox>
                    <el-checkbox label="发票号码"></el-checkbox>
                    <el-checkbox label="发票类型"></el-checkbox>
                    <el-checkbox label="是否代开"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-tab-pane>
              <el-tab-pane label="银行" name="third">
                <div class="right_title none-border">
                  <h3>凭证习惯</h3>
                  <p>
                    *温馨提示：合并凭证之后不能进行拆分，且会影响现金流量表取数，请谨慎选择合并方式！
                  </p>
                  <el-radio-group v-model="mergeSetInfo.bank">
                    <div class="item_radio">
                      <el-radio :label="0">单张生成</el-radio>
                    </div>
                    <div class="item_radio">
                      <el-radio :label="1"
                        >合并生成 按业务类型（会计科目）</el-radio
                      >
                    </div>
                    <div class="item_radio">
                      <el-radio :label="3"
                        >合并生成 按业务类型（会计科目一级）</el-radio
                      >
                    </div>
                    <div class="item_radio">
                      <el-radio :label="2">合并生成 按时间</el-radio>
                    </div>
                  </el-radio-group>
                  <div class="item_lists">
                    <el-checkbox
                      v-model="mergeSetInfo.bankEntry"
                      :true-label="1"
                      :false-label="0"
                      >结算银行存款分录合并一条</el-checkbox
                    >
                    <el-checkbox
                      v-model="mergeSetInfo.dealingsEntry"
                      :true-label="1"
                      :false-label="0"
                      >结算往来单位分录合并一条</el-checkbox
                    >
                  </div>
                  <h3>摘要自定义</h3>
                  <p>
                    *温馨提示：自定义凭证摘要不可合并凭证！并且银行记录需要每条银行对应一条业务，否则摘要可能显示有误
                  </p>
                  <el-checkbox
                    label="默认业务类型"
                    checked
                    disabled
                    style="padding-left:30px"
                  ></el-checkbox>
                  <el-checkbox-group
                    v-model="mergeSetInfo.bankSummaryArr"
                    style="padding-left:30px"
                  >
                    <el-checkbox label="业务类型"></el-checkbox>
                    <el-checkbox label="备注"></el-checkbox>
                    <el-checkbox label="交易日期"></el-checkbox>
                    <el-checkbox label="单位名称"></el-checkbox>
                    <el-checkbox label="金额"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-tab-pane>
              <!-- <el-tab-pane label="制作人" name="four">
              <div class="right_title none-border">
                <h3>制作人审核人</h3>
                <p>*温馨提示：为空默认登录人！</p>

                <div style="margin-bottom:20px;margin-left: 30px;">
                  制作人：<el-input
                    style="width:200px;"
                    v-model="mergeSetInfo.createUser"
                    placeholder="制作人"
                    size="small"
                  ></el-input>
                  隐藏：
                  <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    v-model="mergeSetInfo.createUserStatus"
                  ></el-switch>
                  （仅在打印时）
                </div>
                <div style="margin-left: 30px;">
                  审核人：<el-input
                    style="width:200px;"
                    v-model="mergeSetInfo.examUser"
                    placeholder="审核人"
                    size="small"
                  ></el-input>
                  隐藏：
                  <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    v-model="mergeSetInfo.examUserStatus"
                  ></el-switch>
                  （仅在打印时）
                </div>
              </div>
            </el-tab-pane> -->
              <el-tab-pane label="凭证生成时间" name="five">
                <div class="right_title none-border">
                  <h3>凭证生成时间</h3>
                  <p>*温馨提示：默认为开票日期！</p>
                  <div style="margin-bottom:20px;">
                    <el-radio-group v-model="mergeSetInfo.timeStatus">
                      <el-radio :label="0" class="item_radio">默认</el-radio>
                      <el-radio :label="1" class="item_radio">月末</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="城建及附加合并" name="six">
                <div class="right_title none-border">
                  <h3>城建及附加合并</h3>
                  <p>*温馨提示：选择“是”则城建及附加合并为一张凭证！</p>
                  <div style="margin-bottom:20px;">
                    <el-radio-group v-model="mergeSetInfo.taxCityVoucher">
                      <el-radio :label="0" class="item_radio">否</el-radio>
                      <el-radio :label="1" class="item_radio">是</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="损益凭证分录合并规则" name="seven">
                <div class="right_title none-border">
                  <h3>本年利润合并显示</h3>
                  <p>*温馨提示：选择“是”则本年利润合并为一条明细！</p>
                  <div style="margin-bottom:20px;">
                    <el-radio-group v-model="mergeSetInfo.bnlrMerge">
                      <el-radio :label="0">否</el-radio>
                      <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div style="margin-top: 20px;text-align: right;">
              <el-button
                type="primary"
                @click="mergeOk"
                size="small"
                :disabled="!$buttonStatus('xgsz_plbc')"
                :loading="btnLoading"
                ><i class="iconfont icon-baocun"></i>批量保存</el-button
              >
            </div>
          </div>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane label="账务设置" name="2">
        <el-scrollbar :style="{ height: contentStyleObj }">
          <div style="width: 850px;">
            <div class="right_title none-border">
              <h3>账期设置</h3>
              <p>
                <span style="color: #2c3e50;">账期开放区间：</span
                >&nbsp;&nbsp;&nbsp;&nbsp;
                <el-select
                  size="small"
                  v-model="info.carryYear"
                  filterable
                  placeholder="请选择"
                  style="width:100px"
                >
                  <el-option label="月" :value="0"></el-option>
                  <el-option label="年" :value="1"></el-option>
                </el-select>
                <span style="color:red;padding-left:10px"
                  >*开启按年开放账期所有账套将同时生成一年数据请谨慎开启</span
                >
              </p>
              <p style="border-top: none;">
                <span style="color: #2c3e50;">进入账簿默认账期：</span>
                <el-select
                  size="small"
                  v-model="info.curPeriod"
                  filterable
                  placeholder="请选择"
                  style="width:100px"
                >
                  <el-option label="最后账期" :value="1"></el-option>
                  <el-option label="当前账期" :value="0"></el-option>
                  <el-option label="已结账下期" :value="2"></el-option>
                </el-select>
                <!-- <span style="color:red;padding-left:10px"
              >*开启按年开放账期所有账套将同时生成一年数据请谨慎开启</span
            > -->
              </p>
            </div>
            <div class="right_title none-border">
              <h3>会计报表重分类</h3>
              <p>
                <el-radio-group v-model="info.kjcfl">
                  <el-radio
                    v-for="item in kjcflOption"
                    :label="item.value"
                    :key="item.index"
                    >{{ item.name }}</el-radio
                  >
                </el-radio-group>
              </p>
              <h3 style="font-size: 13px;font-weight: 500;">备注</h3>
              <p style="color:#333 !important">
                设置重分类：调表不调账
                <br />（1）不重分类：应收应付，预收预付，其他应收应付，负数正常显示，报表不调整
                <br />（2）部分重分类：应收应付，预收预付，总账科目出现负数重分类
                <br />（3）全部重分类：应收应付，预收预付，其他应收应付，总账科目出现负数重分类
                <br />（4）部分明细重分类：应收应付，预收预付，明细科目出现负数重分类
                <br />（5）全部明细重分类：应收应付，预收预付，其他应收应付，明细科目出现负数重分类
              </p>
            </div>
            <div class="right_title none-border">
              <h3>应交税款重分类</h3>
              <p>
                <el-radio-group v-model="info.kjcfl2221">
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
              </p>
              <h3 style="font-size: 13px;font-weight: 500;">备注</h3>
              <p style="color:#333 !important">
                不重分类：按照应交税费一级科目余额取值
              </p>
              <p style="color:#333 !important;border-top: none;">
                重分类：按照应交税费明细科目贷方余额取值，借方余额体现在其他流动资产（政策依据：财会[2016]22号文件）
              </p>
            </div>
            <div class="right_title none-border">
              <h3 style="border-bottom: 1px solid #eee;">打印、导出设置</h3>
              <div class="print_style">
                打印、导出是否打印右下角落款：
                <el-radio-group v-model="info.needInscribe">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
                <div v-if="info.needInscribe">
                  <el-input
                    size="small"
                    v-model="info.inscribe"
                    style="width: 200px;margin-left:10px"
                    placeholder="请输入落款名称"
                  ></el-input>
                  <span style="margin-left: 10px;color: red;"
                    >*不填默认为系统名称</span
                  >
                </div>
              </div>
              <div style="margin-top: 20px;font-size: 12px;">
                打印、导出是否打印制作人和审核人：
                <el-radio-group v-model="info.needZzrShr">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
                <span style="margin-left: 10px;color: red;"
                    >*不含凭证</span
                  >
              </div>
              <div style="margin-top: 20px;font-size: 12px;">
                打印、导出是否显示当天日期：
                <el-radio-group v-model="info.printingDate">
                  <el-radio :label="0">是</el-radio>
                  <el-radio :label="1">否</el-radio>
                </el-radio-group>
              </div>
            </div>
            <!-- 短信验证码显示设置 -->
            <div class="right_title none-border">
              <h3 style="border-bottom: 1px solid #eee;">短信显示设置</h3>
              <div style="margin-top: 20px;font-size: 12px;">
                <el-radio-group v-model="info.smsShow">
                  <el-radio :label="0">全部显示</el-radio>
                  <el-radio :label="1">根据账号权限显示</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div style="margin-top: 20px; text-align: right;">
              <el-button
                type="primary"
                @click="saveInfo"
                size="small"
                :disabled="!$buttonStatus('xgsz_bc')"
                :loading="oneLoading"
                ><i class="iconfont icon-baocun"></i>保存</el-button
              >
            </div>
          </div>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane label="申报设置" name="3">
        <orgSetting ref="orgSetting" @success="getInfo"/>
      </el-tab-pane>
      <el-tab-pane label="Ai财务工厂设置" name="4">
        <div style="width:850px">
          <div class="right_title none-border">
            <h3>启动设置</h3>
            <p>
              <span style="color: #2c3e50;">启动时间：</span
              >&nbsp;&nbsp;&nbsp;&nbsp;
              <el-select
                size="small"
                v-model="info.autoStartTime"
                filterable
                placeholder="请选择"
                style="width:100px"
                clearable
                @clear="info.autoStartTime = null"
              >
              <el-option
                v-for="n in range(2, 10)"
                :key="n"
                :label="n.toString() + '日'"
                :value="n"
              ></el-option>
              </el-select>
              <span style="color:red;padding-left:10px"
                >*设置启动时间将按照启动时间启动不根据节假日顺延</span
              >
            </p>
          </div>
          <div style="margin-top: 20px; text-align: right;">
            <el-button
              type="primary"
              @click="saveInfo"
              size="small"
              :disabled="!$buttonStatus('xgsz_bc')"
              :loading="oneLoading"
              ><i class="iconfont icon-baocun"></i>保存</el-button
            >
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mergeSetOrg, mergeInfoOrg } from "@/api/voucher";
import { orgSettingInfo, orgSettingSave } from "@/api/org";
import orgSetting from "../ea-setting/org-setting.vue";
export default {
  name: "orgHabbitSetting",
  components: {
    orgSetting,
  },
  data() {
    return {
      activeName: "first",
      value: "1",
      checked: true,
      mergeSetInfo: {
        timeStatus: 0,
        in: undefined,
        out: undefined,
        bank: undefined,
        outSummaryArr: [],
        inSummaryArr: [],
        bankSummaryArr: [],
        bankInfo: [],
        inEntry: undefined,
        outEntry: undefined,
        createUserStatus: 0,
        examUserStatus: 0,
      },
      contentStyleObj: {}, //高度变化
      fullscreenLoading: false,
      info: {
        inscribe: "",
        printingDate: 0,
      },
      btnLoading: false,
      oneLoading: false,
      kjcflOption: [
        { name: "不重分类", value: 0 },
        {
          name: "部分重分类",
          value: 2,
        },
        {
          name: "全部重分类",
          value: 1,
        },
        {
          name: "部分明细重分类",
          value: 4,
        },
        {
          name: "全部明细重分类",
          value: 3,
        },
      ],
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(166);
    this.getList();
    this.getInfo();
  },
  mounted(){
    if (this.$store.getters["user/model"] == "财务核算") {
      this.$store.dispatch('tagsView/delCachedViewByName', "bookkeeping")
    }
  },
  methods: {
    getList() {
      mergeInfoOrg({}).then((res) => {
        let merge = res.data.data.info;
        this.mergeSetInfo.in = merge.in;
        this.mergeSetInfo.out = merge.out;
        this.mergeSetInfo.inEntry = merge.inEntry;
        this.mergeSetInfo.outEntry = merge.outEntry;
        this.mergeSetInfo.bankEntry = merge.bankEntry;
        this.mergeSetInfo.dealingsEntry = merge.dealingsEntry;
        // if(merge.bankEntry == 1){
        //   this.mergeSetInfo.bankEntry = true
        // }else{
        //   this.mergeSetInfo.bankEntry = false
        // }
        // if(merge.dealingsEntry == 1){
        //   this.mergeSetInfo.dealingsEntry = true
        // }else{
        //   this.mergeSetInfo.dealingsEntry = false
        // }

        this.mergeSetInfo.bank = merge.bank;
        this.mergeSetInfo.examUser = merge.examUser;
        this.mergeSetInfo.createUser = merge.createUser;
        this.mergeSetInfo.examUserStatus = merge.examUserStatus;
        this.mergeSetInfo.createUserStatus = merge.createUserStatus;
        this.mergeSetInfo.timeStatus = merge.timeStatus;
        this.mergeSetInfo.taxCityVoucher = merge.taxCityVoucher
        this.mergeSetInfo.bnlrMerge = merge.bnlrMerge

        let arrIn = [];
        merge.inSummary.split(",").map((v) => {
          if (v && v != "默认业务类型") {
            arrIn.push(v);
          }
        });

        let arrOut = [];
        merge.outSummary.split(",").map((v) => {
          if (v && v != "默认业务类型") {
            arrOut.push(v);
          }
        });

        let arrBank = [];
        merge.bankSummary.split(",").map((v) => {
          if (v && v != "默认业务类型") {
            arrBank.push(v);
          }
        });

        this.mergeSetInfo.outSummaryArr = arrOut;
        this.mergeSetInfo.inSummaryArr = arrIn;
        this.mergeSetInfo.bankSummaryArr = arrBank;
      });
    },
    getInfo() {
      orgSettingInfo({}).then((res) => {
        if (res.data.msg == "success") {
          this.info = res.data.data;
          this.info.autoStartTime = res.data.data.autoStartTime ? res.data.data.autoStartTime : null
          this.info.kjcfl2221 = res.data.data.kjcfl2221 ? res.data.data.kjcfl2221 : '否'
          this.$refs.orgSetting.init(res.data.data)
        }
      });
    },
    mergeOk() {
      this.btnLoading = true;
      // this.fullscreenLoading = true
      this.mergeSetInfo.in = this.mergeSetInfo.in * 1;
      this.mergeSetInfo.out = this.mergeSetInfo.out * 1;
      this.mergeSetInfo.bank = this.mergeSetInfo.bank * 1;
      this.mergeSetInfo.inEntry = this.mergeSetInfo.inEntry * 1;
      this.mergeSetInfo.outEntry = this.mergeSetInfo.outEntry * 1;
      this.mergeSetInfo.bankEntry = this.mergeSetInfo.bankEntry * 1;
      this.mergeSetInfo.dealingsEntry = this.mergeSetInfo.dealingsEntry * 1;

      // if(this.mergeSetInfo.bankEntry){
      //   this.mergeSetInfo.bankEntry = 1
      // }else{
      //   this.mergeSetInfo.bankEntry = 0
      // }
      // if(this.mergeSetInfo.dealingsEntry){
      //   this.mergeSetInfo.dealingsEntry = 1
      // }else{
      //   this.mergeSetInfo.dealingsEntry = 0
      // }
      this.mergeSetInfo.inSummary = this.mergeSetInfo.inSummaryArr.join(",");
      this.mergeSetInfo.outSummary = this.mergeSetInfo.outSummaryArr.join(",");
      this.mergeSetInfo.bankSummary = this.mergeSetInfo.bankSummaryArr.join(
        ","
      );
      // if (
      //   (this.mergeSetInfo.inSummary.match("开票日期") ||
      //     this.mergeSetInfo.inSummary.match("单位名称") ||
      //     this.mergeSetInfo.inSummary.match("开票项目") ||
      //     this.mergeSetInfo.inSummary.match("金额") ||
      //     this.mergeSetInfo.inSummary.match("发票号码")) &&
      //   this.mergeSetInfo.in != 0
      // ) {
      //   this.$message.error("自定义凭证摘要不可合并凭证！");
      //   this.getList();
      //   return;
      // }
      // if (
      //   (this.mergeSetInfo.outSummary.match("开票日期") ||
      //     this.mergeSetInfo.outSummary.match("单位名称") ||
      //     this.mergeSetInfo.outSummary.match("开票项目") ||
      //     this.mergeSetInfo.outSummary.match("金额") ||
      //     this.mergeSetInfo.outSummary.match("发票号码")) &&
      //   this.mergeSetInfo.out != 0
      // ) {
      //   this.$message.error("自定义凭证摘要不可合并凭证2！");
      //   this.getList();
      //   return;
      // }
      mergeSetOrg(this.mergeSetInfo).then((res) => {
        this.btnLoading = false;
        // this.fullscreenLoading = false
        if (res.data.msg == "success") {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getList();
        }
      });
    },
    saveInfo() {
      this.oneLoading = true;
      this.info.cjInvoiceInType = this.info.cjInvoiceInType.toString()
      orgSettingSave(this.info).then((res) => {
        this.oneLoading = false;
        if (res.data.msg == "success") {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getInfo();
        }
      });
    },
    range(start, end) {
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
};
</script>

<style lang="scss" scoped>
.right_title {
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;
  h3 {
    font-size: 14px;
    padding-bottom: 10px;
  }
}
.right_title h5 {
  font-weight: normal;
}
.right_title p {
  font-size: 12px;
  color: red !important;
  text-align: left !important;
  padding: 5px;
}
.item_radio,
.one_item {
  width: 100%;
  padding-left: 30px;
  height: 33px;
}
.item_lists {
  width: 100%;
  padding-left: 60px;
}
.el-radio-group {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
}
.none-border p {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  /// height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top_hint {
  color: orange;
  font-size: 13px;
  position: absolute;
  top: 12px;
  z-index: 999;
  left: 405px;
  display: flex;
  align-items: center;
}
.print_style{
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 12px;
}
</style>